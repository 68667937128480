/**
 * Replacement for jquery $(document).ready()
 *
 * https://stackoverflow.com/a/7053197/5769763
 */

function ready(callback) {
  if (document.readyState !== 'loading') {
    callback();
  } else {
    document.addEventListener('DOMContentLoaded', callback);
  }
}

export default ready;
