import ready from 'geosurvey/static/js/ready';

ready(() => {
  // Responsive Menu
  document.getElementById('menu-trigger').onclick = () => {
    const nav = document.querySelector('nav#top');
    if (nav.classList.contains('open')) {
      nav.classList.remove('open');
    } else {
      nav.classList.add('open');
    }
  };

  const sendPost = linkElem => {
    const form = document.getElementById('action_form');
    form.setAttribute('action', linkElem.getAttribute('href'));
    form.submit();
  };

  // Send POST request on link click
  Array.from(document.querySelectorAll('a.post-on-click')).forEach(link => {
    link.addEventListener('click', e => {
      e.preventDefault();
      sendPost(e.target);
    });
  });
});
